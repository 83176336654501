.participants {
  display: flex;
  flex-wrap: wrap;
}

.participant {
  width: 300px;
  margin: 10px;
}

video {
  width: 100%;
  height: auto;
}


.chat-box {
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  height: 200px;
  overflow-y: auto;
}

.chat-box div {
  margin-bottom: 10px;
}


